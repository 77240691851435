<style scoped lang="less">
  .content {
    padding: 20px;
    box-sizing: border-box;
  }
  .text-field, .text-value {
    white-space: nowrap;
  }
  .text-value {
    font-weight: 500;
  }
  .row {
    white-space: nowrap;
    word-break: keep-all;
    margin-top: 15px;
  }
  .item {
    & + .item {
      margin-top: 15px;
    }
  }
</style>

<style lang="less">
  .resume-details-certificate-other {
    .ivu-timeline {
      display: block;
      width: 100%;
    }
  }
</style>

<template>
  <r-card hborder class="resume-details-certificate-other">
    <span slot="title">执业证书</span>
    <div class="content">
      <div class="item" v-for="(item, i) in data" :key="item.id + '.' + i">
        <div class="text-value">{{item.mType}}</div>
        <div style="display: flex;flex-direction: row;align-items: flex-start;">
          <div>
            <div class="row">
              <span class="text-field">发证时间：</span>
              <span class="text-value">{{(item.getDate || '').substr(0, 10).replace(/\-/g, '/')}}</span>
              <span class="text-field" style="margin-left: 45px;">执业类别：</span>
              <span class="text-value">{{item.practiceType}}</span>
              <span class="text-field" style="margin-left: 45px;">执业范围：</span>
              <span class="text-value">{{item.practiceRange}}</span>
            </div>
            <div class="row" style="display: flex; align-items: flex-start;">
              <span class="text-field">扫描件：</span>
              <r-imgs style="flex: 1;width: 0;" v-if="item.sources" :covers="item.sources.covers" :previews="item.sources.previews" />
            </div>
          </div>
          <div class="row" style="display: flex;flex: 1;margin-left: 20px;align-items: flex-start;">
            <span class="text-field">变更记录：</span>
            <Timeline>
              <TimelineItem v-for="(log, i) in item.updateLogList" :key="log.id + '.' + i">
                <template v-if="log.type === 'change'">
                  <span class="text-field">变更注册：</span>
                  <span class="text-value">{{(log.changeDate || '').substr(0, 10).replace(/\-/g, '/')}}</span>
                  <span class="text-value">{{log.changeContent}}</span>
                </template>
                <template v-if="log.type === 'goon'">
                  <span class="text-field">延续注册：</span>
                  <span class="text-value">{{(log.registDate || '').substr(0, 10).replace(/\-/g, '/')}}</span>
                  —
                  <span class="text-value">{{(log.registEndDate || '').substr(0, 10).replace(/\-/g, '/')}}</span>
                </template>
                <template v-if="log.type === 'first'">
                  <span class="text-field">首次注册：</span>
                  <span class="text-value">{{(log.registDate || '').substr(0, 10).replace(/\-/g, '/')}}</span>
                  —
                  <span class="text-value">{{(log.registEndDate || '').substr(0, 10).replace(/\-/g, '/')}}</span>
                </template>
              </TimelineItem>
            </Timeline>
          </div>
        </div>
      </div>
    </div>
  </r-card>
</template>

<script>
import RCard from '../../components/card'
import RImgs from '../../components/imgs'

import { workerCertificateRequest } from '../../../../api'
import { loadImgs } from '@/components/base/file/libv2'

export default {
  components: { RCard, RImgs },
  data () {
    return {
      data: []
    }
  },
  computed: {
    id () {
      return this.$route.query.id
    }
  },
  watch: {
    id () {
      this.loadData()
    }
  },
  methods: {
    async loadData () {
      const data = await workerCertificateRequest.get({ workerId: this.id, type: 'doctor_pra' })

      data.forEach(item => {
        try {
          item.updateLogList = item.updateLog ? JSON.parse(item.updateLog) : []
        } catch (error) {
          item.updateLogList = []
        }
      })
      
      this.data = data

      this.data.forEach(async (item, i) => {
        item.sources = await loadImgs([item], (item) => item.mType)
        this.data.splice(i, 1, item)
      })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
