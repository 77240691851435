import { render, staticRenderFns } from "./pra.vue?vue&type=template&id=151c580b&scoped=true&"
import script from "./pra.vue?vue&type=script&lang=js&"
export * from "./pra.vue?vue&type=script&lang=js&"
import style0 from "./pra.vue?vue&type=style&index=0&id=151c580b&prod&scoped=true&lang=less&"
import style1 from "./pra.vue?vue&type=style&index=1&id=151c580b&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../yiweb/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "151c580b",
  null
  
)

export default component.exports