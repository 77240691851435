import { render, staticRenderFns } from "./edu.vue?vue&type=template&id=af0d1a60&scoped=true&"
import script from "./edu.vue?vue&type=script&lang=js&"
export * from "./edu.vue?vue&type=script&lang=js&"
import style0 from "./edu.vue?vue&type=style&index=0&id=af0d1a60&prod&scoped=true&lang=less&"
import style1 from "./edu.vue?vue&type=style&index=1&id=af0d1a60&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../../yiweb/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af0d1a60",
  null
  
)

export default component.exports