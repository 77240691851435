<style scoped lang="less">
  .content {
    padding: 20px;
    box-sizing: border-box;
  }
  .text-field, .text-value {
    white-space: nowrap;
  }
  .text-value {
    font-weight: 500;
  }
  .row {
    white-space: nowrap;
    word-break: keep-all;
    margin-top: 15px;
  }
  .item {
    & + .item {
      margin-top: 15px;
    }
  }
</style>

<style lang="less">
  .resume-details-job {
    .ivu-timeline {
      display: block;
      width: 100%;
    }
  }
</style>

<template>
  <r-card hborder class="resume-details-job">
    <span slot="title">上岗经历</span>
    <div class="content">
      <div>
        <div v-for="item in data" :key="item.id" class="item">
          <div class="text-value">{{item.mLevel}}{{item.type}}</div>
          <div class="row">
            <span class="text-field">岗位等级：</span>
            <span class="text-value">{{item.jobTitleTitle}}</span>
            <span class="text-field" style="margin-left: 60px;">聘期时间：</span>
            <span class="text-value">
              {{(item.hireStartDate || '').substr(0, 10).replace(/\-/g, '/')}}
              —
              {{(item.hireEndDate || '').substr(0, 10).replace(/\-/g, '/')}}
            </span>
            <span class="text-field" style="margin-left: 60px;">专业：</span>
            <span class="text-value">{{item.type}}</span>
            <span class="text-field" style="margin-left: 60px;">岗位类型：</span>
            <span class="text-value">{{item.jobTitleType}}</span>
          </div>
          <Timeline style="margin-top: 30px;" v-if="item.updateLogList && item.updateLogList.length">
            <TimelineItem v-for="(log, i) in item.updateLogList" :key="item.id + '.' + i">
              <div class="item">
                <div style="display: flex;width: 50vw;min-width: 700px;justify-content: space-between;">
                  <div>
                    <span class="text-field">岗位等级：</span>
                    <span class="text-value">{{log.mType}}</span>
                  </div>
                  <div>
                    <span class="text-field">聘期时间：</span>
                    <span class="text-value">{{(log.hireStartDate || '').substr(0, 10).replace(/\-/g, '/')}}—{{(log.hireEndDate || (log.hireStartDate ? '至今' : '')).substr(0, 10).replace(/\-/g, '/')}}</span>
                  </div>
                </div>
                <div style="display: flex;width: 50vw;min-width: 700px;justify-content: space-between;margin-top: 20px;">
                  <div>
                    <span class="text-field">专业：</span>
                    <span class="text-value">{{log.hirePro}}</span>
                  </div>
                  <div>
                    <span class="text-field">岗位类型：</span>
                    <span class="text-value">{{log.xlmc}}</span>
                  </div>
                </div>
              </div>
            </TimelineItem>
          </Timeline>
          <r-imgs v-if="item.certificate && item.certificate.sources" :name="item.certificate.hireWorker" :covers="item.certificate.sources.covers" :previews="item.certificate.sources.previews" />
        </div>
      </div>
    </div>
  </r-card>
</template>

<script>
import RCard from '../components/card'
import RImgs from '../components/imgs'

import { workerJobTitleRequest, workerCertificateRequest } from '../../../api'
import { loadImgs } from '@/components/base/file/libv2'

export default {
  components: { RCard, RImgs },
  data () {
    return {
      data: []
    }
  },
  computed: {
    id () {
      return this.$route.query.id
    }
  },
  watch: {
    id () {
      this.loadData()
    }
  },
  methods: {
    async loadData () {
      const data = await workerJobTitleRequest.get({ workerId: this.id })
      data.sort((a, b) => {
        return a.hireStartDate > b.hireStartDate
      })

      const certificate = await workerCertificateRequest.get({ type: 'pro_hire', workerId: this.id })

      data.forEach(item => {
        item.certificate = certificate.find(v => v.xlmc === item.jobTitleType && v.mType === item.jobTitleTitle)
        try {
          item.updateLogList = item.certificate.updateLog ? JSON.parse(item.certificate.updateLog) : []
        } catch (e) {
          item.updateLogList = []
        }
      })

      this.data = data

      this.data.forEach(async (item, i) => {
        if (item.certificate) {
          item.certificate.sources = await loadImgs([item.certificate], (item) => item.hireWorker)
          this.data.splice(i, 1, item)
        }
      })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
