<style scoped lang="less">
  .content {
    padding: 20px;
    box-sizing: border-box;
  }
  .text-field, .text-value {
    white-space: nowrap;
  }
  .text-value {
    font-weight: 500;
  }
  .row {
    white-space: nowrap;
    word-break: keep-all;
    margin-top: 15px;
  }
  .item {
    & + .item {
      margin-top: 15px;
    }
  }
</style>

<style lang="less">
  .resume-details-certificate-other {
    .ivu-timeline {
      display: block;
      width: 100%;
    }
  }
</style>

<template>
  <r-card hborder class="resume-details-certificate-other">
    <span slot="title">其他证书</span>
    <div class="content">
      <div class="item" v-for="(item, i) in data" :key="item.id + '.' + i">
        <div class="text-value">{{item.mType || '-'}}</div>
        <div class="row">
          <span class="text-field">证书编码：</span>
          <span class="text-value">{{item.code}}</span>
          <span class="text-field" style="margin-left: 45px;">状态：</span>
          <span class="text-value">{{item.status}}</span>
        </div>
        <div class="row" style="display: flex; align-items: flex-start;">
          <span class="text-field">扫描件：</span>
          <r-imgs style="flex: 1;width: 0;" v-if="item.sources" :covers="item.sources.covers" :previews="item.sources.previews" />
        </div>
      </div>
    </div>
  </r-card>
</template>

<script>
import RCard from '../../components/card'
import RImgs from '../../components/imgs'

import { workerCertificateRequest } from '../../../../api'
import { loadImgs } from '@/components/base/file/libv2'

export default {
  components: { RCard, RImgs },
  data () {
    return {
      data: []
    }
  },
  computed: {
    id () {
      return this.$route.query.id
    }
  },
  watch: {
    id () {
      this.loadData()
    }
  },
  methods: {
    async loadData () {
      const data = await workerCertificateRequest.get({ workerId: this.id, type: 'other' })
      this.data = data

      this.data.forEach(async (item, i) => {
        item.sources = await loadImgs([item], (item) => item.mType || '证书')
        this.data.splice(i, 1, item)
      })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
