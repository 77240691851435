<style scoped lang="less">
  .block {
    & + .block {
      margin-top: 20px;
    }
  }
</style>

<template>
  <div class="resume.details.all">
    <RBase class="block" />
    <RWork class="block" />
    <REdu class="block" />
    <RJob class="block" />
    <RRew class="block" />
    <RTrain class="block" />
    <RCertIndex class="block" />
    <RCertPra class="block" />
    <RCertQua class="block" />
    <RcertOther class="block" />
  </div>
</template>

<script>
import RBase from './base'
import RWork from './work'
import REdu from './edu'
import RJob from './job'
import RRew from './rew'
import RTrain from './train'
import RCertIndex from './certificate/index'
import RCertPra from './certificate/pra'
import RCertQua from './certificate/qua'
import RcertOther from './certificate/other'

export default {
  components: {
    RBase, RWork, REdu, RJob, RRew, RTrain, RCertIndex, RCertPra, RCertQua, RcertOther
  }
}
</script>
