<style scoped lang="less">
  .content {
    padding: 20px;
    box-sizing: border-box;
  }
  .text-field, .text-value {
    white-space: nowrap;
  }
  .text-value {
    font-weight: 500;
  }
  .row {
    white-space: nowrap;
    word-break: keep-all;
    margin-top: 15px;
  }
  .item {
    & + .item {
      margin-top: 15px;
    }
  }
</style>

<style lang="less">
  .resume-details-train {
    .ivu-timeline {
      display: block;
      width: 100%;
    }
  }
</style>

<template>
  <r-card hborder class="resume-details-train">
    <span slot="title">培训信息</span>
    <div class="content">
      <div>
        <div v-for="item in data" :key="item.id" class="item">
          <div class="text-value">{{item.zbdw}}</div>
          <div class="row">
            <span class="text-field">培训项目：</span>
            <span class="text-value">{{item.pxxm}}</span>
          </div>
          <div class="row">
            <span class="text-field">培训性质：</span>
            <span class="text-value">{{item.jypx}}</span>
            <span class="text-field" style="margin-left: 40px;">培训离岗状态：</span>
            <span class="text-value">{{item.lg}}</span>
            <span class="text-field" style="margin-left: 40px;">培训类型：</span>
            <span class="text-value">{{item.type}}</span>
          </div>
          <div class="row">
            <span class="text-field">培训时间：</span>
            <span class="text-value">
              {{(item.startDate || '').substr(0, 10).replace('-', '/')}}
              -
              {{(item.endDate || '').substr(0, 10).replace('-', '/')}}
              （{{item.dayNum}}天）
            </span>
          </div>
          <div style="margin-top: 15px; display: flex;">
            <span class="text-field">扫描件：</span>
            <r-imgs style="flex: 1;width: 0;" v-if="item.sources" :covers="item.sources.covers" :previews="item.sources.previews" />
          </div>
        </div>
      </div>
    </div>
  </r-card>
</template>

<script>
import RCard from '../components/card'
import RImgs from '../components/imgs'

import { trainRequest } from '../../../api'
import { loadImgs } from '@/components/base/file/libv2'

export default {
  components: { RCard, RImgs },
  data () {
    return {
      data: []
    }
  },
  computed: {
    id () {
      return this.$route.query.id
    }
  },
  watch: {
    id () {
      this.loadData()
    }
  },
  methods: {
    async loadData () {
      const data = await trainRequest.get({ workerId: this.id })
      data.sort((a, b) => {
        return a.startDate > b.startDate
      })

      this.data = data

      this.data.forEach(async (item, i) => {
        item.sources = await loadImgs([item], (item) => item.pxxm)
        this.data.splice(i, 1, item)
      })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
