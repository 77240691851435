<style scoped lang="less">
  .content {
    padding: 20px;
    box-sizing: border-box;
    display: flex;
  }
  .text-field, .text-value {
    line-height: 3;
    white-space: wrap;
  }
  .row {
    white-space: wrap;
    word-break: keep-all;
  }
</style>

<template>
  <r-card hborder>
    <span slot="title">基础信息</span>
    <div class="content">
      <div>
        <div class="row">
          <span class="text-field">性别：</span>
          <span class="text-value">{{data.sex || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">年龄：</span>
          <span class="text-value">{{age}}</span>
        </div>
        <div class="row">
          <span class="text-field">民族：</span>
          <span class="text-value">{{data.clan || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">学历：</span>
          <span class="text-value">{{data.education || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">籍贯：</span>
          <span class="text-value">{{data.hometown || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">政治面貌：</span>
          <span class="text-value">{{data.politicalStatus}}</span>
        </div>
      </div>
      <div style="margin-left: 100px;">
        <div class="row">
          <span class="text-field">出生年月：</span>
          <span class="text-value">{{(data.birth || '-').substr(0, 10)}}</span>
        </div>
        <div class="row">
          <span class="text-field">个人身份：</span>
          <span class="text-value">{{data.sf || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">联系电话：</span>
          <span class="text-value">{{data.phone || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">身份证：</span>
          <span class="text-value">{{data.idNo || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">是否特殊人才：</span>
          <span class="text-value">{{data.agreementCode || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">是否港澳台及外籍人士：</span>
          <span class="text-value">{{data.graduatedSchool || '-'}}</span>
        </div>
      </div>
      <div style="flex: 1; width: 0; margin-left: 60px; display: flex; align-items: flex-start;">
        <div class="text-field" style="white-space: wrap;">身份证：</div>
        <r-imgs style="flex: 1; width: 0;" v-if="idCardSources" :previews="idCardSources.previews" :covers="idCardSources.covers" />
      </div>
    </div>
  </r-card>
</template>

<script>
import RCard from '../components/card'
import RImgs from '../components/imgs'
import { getWorkerInfo } from '../libs'
import { getAge } from '@/syslib/tools'
import { loadImgs } from '@/components/base/file/libv2'

export default {
  components: { RCard, RImgs },
  data () {
    return {
      data: {},
      idCardSources: null
    }
  },
  computed: {
    id () {
      return this.$route.query.id
    },
    age () {
      return getAge(this.data.birth, new Date())
    }
  },
  watch: {
    id () {
      this.loadData()
    }
  },
  methods: {
    loadData () {
      getWorkerInfo(this.id).then(async data => {
        this.data = data
        const items = []
        if (data.idCardFrontFileId) {
          items.push({
            name: '正面',
            fileIds: data.idCardFrontFileId
          })
        }
        if (data.idCardBackFileId) {
          items.push({
            name: '背面',
            fileIds: data.idCardBackFileId
          })
        }
        this.idCardSources = await loadImgs(items, (item) => item.name || '身份证')
      })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
